import { useEffect, useState } from "react";
import "./LoginPage.css";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { loadUser, selectUserState, updateCredential, updateInfo } from "../features/user-info/userInfoSlice";
import { CredentialResponse, GoogleLogin, googleLogout } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { JWTUserInfo } from "../models/userModels";
import { Navigate, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { updateCourse } from "../features/chatbot/chatbotSlice";
import { selectCourseState, updateCourseList } from "../features/course/courseSlice";
import { addUserCourse } from "../services/httpService";

function LoginPage() {
    const navigate = useNavigate(); 
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUserState);
    const courseState = useAppSelector(selectCourseState);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (user.userCredential) {
            const expiration = jwtDecode(user.userCredential).exp;
            if (expiration) {
                setTimeout(() => {
                    dispatch(updateInfo(null));
                    dispatch(updateCredential(""));
                    googleLogout();
                    navigate("/login");
                }, expiration * 1000 - Date.now());    
            }
        }
    }, [user.userCredential]);

    useEffect(() => {
        if (user.status === "idle" && user.user) {
            handlePendingCourse();
        }
    }, [user.status, user.user]);

    const handleSuccess = (response: CredentialResponse) => {
        if (response.credential) {
            const data = jwtDecode(response.credential) as JWTUserInfo;

            switch(true) {
                case !data.hd || data.hd !== "umass.edu": 
                    setErrorMessage("Invalid Email");
                    break;

                case !data.name || !data.email: 
                    setErrorMessage("Authentication Error");
                    break;

                default: 
                    dispatch(loadUser({ info: data, credential: response.credential }));
                    dispatch(updateCredential(response.credential));
            }
        } else {
            setErrorMessage("Connection Error");
        }
    }

    const handlePendingCourse = () => {
        const pendingCourseId = localStorage.getItem("pendingCourseId");
        if (pendingCourseId) {
            const pendingCourse = courseState.courseList.find(course => course.id === pendingCourseId);
            if (pendingCourse) {
                dispatch(updateCourse(pendingCourse))
            } else {
                addUserCourse(user.user?.id || "", {course_id: pendingCourseId}, user.userCredential).then(course => {
                    dispatch(updateCourse(course));
                    dispatch(updateCourseList({courses: courseState.courseList.concat(course), userId: user.user?.id || ""}));
                })
            }
            navigate(`/course/${pendingCourseId}`);
            localStorage.removeItem("pendingCourseId");
        } else {
            dispatch(updateCourse(courseState.courseList[0]))
            navigate('/course');
        }    
    }



    const handleError = () => setErrorMessage("Connection Error");

    return (
        <div className="login-page">
            <div className="login-info">
                <div className="logo">
                    Syllapedia
                </div>
                <div className="logo-subtitle">
                    The syllabus scanning and course answering chatbot made for students and instructors
                </div>
            </div>
            <div className="login-container">
                { !user.user ? "Log in to your school email..." : <Navigate to="/course" />}
                <div className="login-error-message">
                    { user.status === 'failed' ? "Connection Error" : errorMessage }
                </div>
                <div className="login-button-container">
                    { 
                        user.status !== 'loading' ?
                            <GoogleLogin onSuccess={handleSuccess} onError={handleError} width={200}/> 
                        :
                            <CircularProgress color="primary" />
                    }
                </div>
            </div>
        </div>
    );
}

export default LoginPage;