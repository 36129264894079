import React, { useState, useCallback } from 'react';
import { Dialog, DialogContent, DialogTitle, Grid, Button, Divider, LinearProgress, Typography, useMediaQuery, Tooltip, ButtonBase, IconButton } from '@mui/material';
import CourseNameView from './CourseNameView';
import SubjectDropdown from './SubjectDropdown';
import CourseInput from './CourseInput';
import UploadSyllabus from './UploadSyllabus';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import { Course, NewSyllabus, CreateCourseInput, subjectToCode, codeToSubject } from '../models/courseModels';
import './CourseDialog.css';

interface dialogProperties {
    open: boolean;
    title: string;
    actionTitle?: string;
    text?: string;
    course?: Course | null;
    courseOptions?: boolean
    courseInfo?: boolean;
    errorHandler?: (c: any) => (string);
    actionHandler?: (...args: any[]) => Promise<any>;
    handleClose?: () => void;
}

function CourseDialog({ open, title, actionTitle, text="", course=null, courseOptions=false, courseInfo, errorHandler=(c: CreateCourseInput) =>  "", actionHandler=() => Promise.resolve(), handleClose=() => {} }: dialogProperties) {
    const [actionStarted, setActionStarted] = useState(false);
    const isMobile = useMediaQuery('(max-width: 520px)');
    const [isCopied, setIsCopied] = useState(false);

    let defaultCourseProperties = {subject:"", number:"", title:"", syllabus: {base64: "", file_type: ""}}
    let [courseProperties, setCourseProperties] = useState(defaultCourseProperties);
    let coursePropertiesHandler = (key: "subject" | "number" | "title" | "syllabus") => {
        if (key === "syllabus") {
            return (value: NewSyllabus) => {};
        } else {
            return (value: string | null) => {};
        }
    };
    let resetCourseProperties = () => {};
    let handleSyllabus = (file: File | null) => {};
    const [error, setError] = useState("");

    if (courseOptions) {
        defaultCourseProperties = course ? {subject:course.subject, number:course.number, title:course.title, syllabus:{base64: course.syllabus.pdf, file_type: "application/pdf"}} : {subject:"", number:"", title:"", syllabus: {base64: "", file_type: ""}};
        [courseProperties, setCourseProperties] = useState(defaultCourseProperties);
        coursePropertiesHandler = (key: "subject" | "number" | "title" | "syllabus") => {
            if (key === "syllabus") {
                return (value: NewSyllabus) => setCourseProperties(prevState => ({...prevState, [key]: value ? value : {base64: "", file_type: ""}}));
            } else {
                return (value: string | null) => {
                    if (key === "subject")  {
                        setCourseProperties(prevState => ({...prevState, [key]: value ? subjectToCode[value as keyof typeof subjectToCode] : ""}));
                    } else {
                        setCourseProperties(prevState => ({...prevState, [key]: value ? value : ""}));
                    }
                };
            }
        };
        resetCourseProperties = () => setCourseProperties(defaultCourseProperties);
        handleSyllabus = (file: File | null) => {
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = (reader.result as string).split(",")[1];
                    (coursePropertiesHandler("syllabus") as ((value: NewSyllabus) => void))({base64: base64String, file_type: file.type});
                };
                reader.onerror = (error) => {
                    console.error("Error reading file:", error);
                    (coursePropertiesHandler("syllabus") as ((value: NewSyllabus) => void))({base64: "", file_type: ""});                
                };
                reader.readAsDataURL(file);
            } else {
                (coursePropertiesHandler("syllabus") as ((value: NewSyllabus) => void))({base64: "", file_type: ""});
            }
        };
    }
    const escape = () => {
        if (!actionStarted) {
            handleClose();
            if (courseOptions) {
                resetCourseProperties();
            }
            setError("");
        }
    };
    const action = () => {
        if (courseOptions)  {
            if (errorHandler(courseProperties)) {
                setError(errorHandler(courseProperties));
            } else {
                setError("");
                setActionStarted(true);
                actionHandler(courseProperties)
                .then(() => {setActionStarted(false);escape();})
                .catch(() => {setActionStarted(false);setError("Course already exists");});
            }
        } else {
            setError("");
            setActionStarted(true);
            actionHandler()
            .then(() => {setActionStarted(false);handleClose();})
            .catch(() => {setActionStarted(false);setError("Course already exists");});
        }
    };

    const LabelValuePair = ({ label, value }: {label: string, value: string}) => (
        <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={4}>
            <Typography variant="subtitle1" fontWeight="bold">
              {label}:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="body1">{value}</Typography>
          </Grid>
        </Grid>
      );
      
    const handleCopyEmail = () => {
        if (course?.instructor.email) {
            navigator.clipboard.writeText(course.instructor.email);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
        }
    };

    return (
        <Dialog open={open} onClose={escape}>
            <DialogTitle>{title}</DialogTitle>
            {actionStarted ? <LinearProgress style={{marginTop: "-3px"}}/> : <Divider></Divider>}
            <DialogContent style={{display: "flex", alignSelf: "center", width:(isMobile ? "80%" : "380px")}}>
                <Grid container spacing={1}>
                    {courseOptions && 
                        <>
                            <Grid item xs={12}>
                                <CourseNameView courseProperties={courseProperties} />
                            </Grid>
                            <Grid item xs={6}>
                                <SubjectDropdown handleSubject={coursePropertiesHandler("subject") as (value: string | null) => void} size={isMobile ? "small" : "medium"} defaultValue={courseProperties.subject ? codeToSubject[courseProperties.subject as keyof typeof codeToSubject] : undefined} />
                            </Grid><Grid item xs={6}>
                                <CourseInput handleInput={coursePropertiesHandler("number") as (value: string | null) => void} size={isMobile ? "small" : "medium"} label="Number" defaultValue={courseProperties.number ? courseProperties.number : undefined} />
                            </Grid><Grid item xs={12}>
                                <CourseInput handleInput={coursePropertiesHandler("title") as (value: string | null) => void} size={isMobile ? "small" : "medium"} label="Name" defaultValue={courseProperties.title ? courseProperties.title : undefined} />
                            </Grid><Grid item xs={12}>
                                <UploadSyllabus syllabus={courseProperties.syllabus} handleSyllabus={handleSyllabus} size={isMobile ? "small" : "medium"} disabled={actionStarted} />
                            </Grid>
                        </>
                    }
                    {courseInfo &&
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <LabelValuePair 
                                    label="Course" 
                                    value={course?.name || 'N/A'} 
                                />
                                <LabelValuePair 
                                    label="Instructor" 
                                    value={course?.instructor.name || 'N/A'} 
                                />
                                <Grid container spacing={1} sx={{ mb: 2 }}>
                                    <Grid item xs={12} sm={4}>
                                        <Typography variant="subtitle1" fontWeight="bold">
                                            Contact:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Typography variant="body1" component="span">
                                            {course?.instructor.email || 'N/A'}
                                        </Typography>
                                        {course?.instructor.email && (
                                            <Tooltip title={isCopied ? "Copied" : "Copy Email"}>
                                                <IconButton
                                                    onClick={handleCopyEmail}
                                                    size="small"
                                                    sx={{ ml: 1, verticalAlign: 'middle' }}
                                                >
                                                    {isCopied 
                                                    ? <CheckIcon fontSize="small" />
                                                    : <ContentCopyIcon fontSize="small" />}
                                                    
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Grid>
                                </Grid>
                                <LabelValuePair
                                    label="Last Updated"
                                    value={course ? new Date(course.last_updated).toLocaleDateString() : 'N/A'}
                                />
                            </Grid>
                      </Grid>
                    }
                    {text && <Grid item xs={12} className="text">{text}</Grid>}
                    {error 
                        ? <Grid item xs={12}>
                            <Typography className="error-text" color={"error"}>
                                {error}
                            </Typography>
                        </Grid>
                        : <Grid item xs={12} height={"16px"}></Grid>

                    } 
                    <Grid item xs={12} style={{display: "flex", justifyContent: "end"}}>
                        <Button onClick={escape} size={isMobile ? "small" : "medium"} variant="outlined" color="inherit" disabled={actionStarted}>
                            Close
                        </Button>
                        {actionTitle !== undefined && <Button size={isMobile ? "small" : "medium"} variant="contained" color="primary" onClick={action} disabled={actionStarted} style={{marginLeft: "8px"}}>
                            {actionTitle}
                        </Button>}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default CourseDialog;
