import "./CoursePage.css";
import ChatInput from "../components/ChatInput";
import ChatContent from "../components/ChatContent";
import { selectChatbotState } from "../features/chatbot/chatbotSlice";
import { useAppSelector } from "../app/hooks";
import { selectCourseState } from "../features/course/courseSlice";
import PdfViewer from "../components/PdfViewer";
import { IconButton, Tooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import CheckIcon from '@mui/icons-material/Check';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import ArticleIcon from '@mui/icons-material/Article';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CourseDialog from "../components/CourseDialog";

function CoursePage() {
    const chatbot = useAppSelector(selectChatbotState);  
    const courseState = useAppSelector(selectCourseState);
    const [linkCopied, setLinkCopied] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [chat, setChat] = useState(false);
    const navigate = useNavigate(); 

    useEffect(() => {
        if (chatbot.course?.id) {
            navigate(`/course/${chatbot.course?.id}`);
        } else {
            navigate("/course");
        }
        setLinkCopied(false);
      }, 
      [chatbot.course?.id]
    );

    const copyLink = () => {
        navigator.clipboard.writeText(location.href);
        setLinkCopied(true);
        setTimeout(() => setLinkCopied(false), 2000);
    };

    const downloadSyllabus = () => {
        const course = courseState.courseList.find(course => course.id === chatbot.course?.id);
        if (course && course.syllabus.pdf) {
            const link = document.createElement('a');
            link.href = `data:application/pdf;base64,${course.syllabus.pdf}`;
            link.download = `${course.name} Syllabus.pdf`;
            link.click();
        }
    };

    const courseInfo = () => {
        setDialogOpen(true);
    };

    const handleChatToggle = () => {
        setChat(!chat);
        setLinkCopied(false);
    };

    return (
        <div className="course-page">
            <CourseDialog 
                open={dialogOpen}
                title={"Course Info"}
                courseInfo={true}
                course={chatbot.course}
                handleClose={() => setDialogOpen(false)}
            />

            <div className="header">
                <div className="title">
                    {courseState.status == "idle" 
                    ? chatbot.course 
                        ? chatbot.course.name 
                        : "No Course Selected" 
                    : ""}
                </div>
                <div className="course-buttons">
                    {courseState.status == "idle" && chatbot.course 
                        ? chat 
                            ? <Tooltip title="Syllabus">
                                <IconButton onClick={() => setChat(false)}>
                                    <ArticleIcon color="primary" />
                                </IconButton>
                            </Tooltip>
                            : <>
                                {linkCopied 
                                    ? <Tooltip title="Copied">
                                        <IconButton onClick={copyLink}>
                                            <CheckIcon />
                                        </IconButton>
                                    </Tooltip>
                                    : <Tooltip title="Copy Link">
                                        <IconButton onClick={copyLink}>
                                            <LinkIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                <Tooltip title="Download">
                                    <IconButton onClick={downloadSyllabus}>
                                        <DownloadIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Info">
                                    <IconButton onClick={courseInfo}>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Chat">
                                    <IconButton onClick={handleChatToggle}>
                                        <AutoAwesomeIcon color="primary" />
                                    </IconButton>
                                </Tooltip>
                            </>
                        : <></>
                    }
                </div>
            </div>
            
            {courseState.status == "idle" && chatbot.course ? chat 
                    ? <div className="course-page-content">
                        <div className="scrollable-chat">
                            <span className="scroll-start-at-top"></span>
                            <ChatContent setChatOpen={setChat}/>
                        </div>
                        <ChatInput />
                    </div>
                    : <PdfViewer base64Pdf={(chatbot.course?.syllabus.pdf) ? (chatbot.highlight ? chatbot.highlight.base64 : chatbot.course.syllabus.pdf) : ""} defaultPage={chatbot.highlight ? chatbot.highlight.page_number : -1} />
                : <></>}
        </div>
    );
}

export default CoursePage;