import { ChatInput } from "../models/chatbotModels";
import { CreateUserInput, AddUserCourseInput } from "../models/userModels";
import { CreateCourseInput, SetCourseInput, CourseSearchInput } from "../models/courseModels";

const API_URL = 'https://syllapedia.azurewebsites.net';
//const API_URL = 'http://localhost:8000';

let controller = new AbortController();

export const abortHttpRequests = () => {
    controller.abort();
    controller = new AbortController();
}

export const chat = async (info: ChatInput, authToken: string) => await httpPost('/api/chat', authToken, info);
export const getUser = async (id: string, authToken: string) => await httpGet(`/api/user/${id}`, authToken);
export const getUserCourses = async (id: string, authToken: string) => await httpGet(`/api/user/${id}/courses`, authToken);
export const userSearchCourses = async (id: string, query: CourseSearchInput, authToken: string) => await httpGet(`/api/user/${id}/courses/search?${objToQueryParams(query)}`, authToken);
export const createUser = async (info: CreateUserInput, authToken: string) => await httpPost('/api/user', authToken, info);
export const addUserCourse = async (id: string, payload: AddUserCourseInput, authToken: string) => await httpPut(`/api/user/${id}/courses`, authToken, payload);
export const removeUserCourse = async (id: string, courseId: string, authToken: string) => await httpDelete(`/api/user/${id}/courses/${courseId}`, authToken);
export const deleteUser = async (id: string, authToken: string) => await httpDelete(`/api/user/${id}`, authToken);
export const createCourse = async (payload: CreateCourseInput, authToken: string) => await httpPost('/api/course', authToken, payload);
export const setCourse = async (id: string, payload: SetCourseInput, authToken: string) => await httpPatch(`/api/course/${id}`, authToken, payload);
export const deleteCourse = async (id: string, authToken: string) => await httpDelete(`/api/course/${id}`, authToken);

function objToQueryParams(obj: any) {
    return new URLSearchParams(obj as Record<string, string>).toString();
}

async function httpGet(url: string, authToken: string) {
    const requestInfo = { 
        method: "GET",
        signal: controller.signal, 
        headers: {
            "Authorization": "Bearer " + authToken
        }, 
    };
    
    const response = await fetch(API_URL + url, requestInfo);
        
    if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
    }

    return await response.json();
}

async function httpPost(url: string, authToken: string, payload?: Object) {
    const requestInfo = { 
        method: "POST",
        signal: controller.signal, 
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + authToken
        },
        body: payload ? JSON.stringify(payload) : null
    };

    const response = await fetch(API_URL + url, requestInfo);
        
    if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
    }

    return await response.json();
}

async function httpPatch(url: string, authToken: string, payload?: Object) {
    const requestInfo = { 
        method: "PATCH", 
        signal: controller.signal, 
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + authToken
        },
        body: payload ? JSON.stringify(payload) : null
    };

    const response = await fetch(API_URL + url, requestInfo);
        
    if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
    }

    return await response.json();
}

async function httpPut(url: string, authToken: string, payload?: Object) {
    const requestInfo = { 
        method: "PUT", 
        signal: controller.signal, 
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + authToken
        },
        body: payload ? JSON.stringify(payload) : null
    };

    const response = await fetch(API_URL + url, requestInfo);
        
    if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
    }

    return await response.json();
}

async function httpDelete(url: string, authToken: string) {
    const requestInfo = { 
        method: "DELETE",
        signal: controller.signal, 
        headers: {
            "Authorization": "Bearer " + authToken
        }, 
    };
    
    const response = await fetch(API_URL + url, requestInfo);
        
    if (!response.ok) {
        throw new Error(`HTTP Error: ${response.status}`);
    }

    return await response.json();
}
