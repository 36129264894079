import "./SentimentAnalytics.css";
import "../pages/AnalyticsPage.css"
import {  useAppSelector } from "../app/hooks";
import { selectUserState } from "../features/user-info/userInfoSlice";
import { useTheme } from '@mui/material/styles';
import { Box, Typography } from "@mui/material";

function SentimentAnalytics() {
    const user = useAppSelector(selectUserState);
    const theme = useTheme();

    return (
        <div className="analytics-info">
            <div className="sentiment-grid-info">
                <Box className="info-box">
                    <Typography variant="h6" color={theme.palette.text.primary} sx={{ textAlign: "center", margin: "20px 0px 20px 0px" }}>
                        Clustering
                    </Typography>
                </Box>
            </div>
        </div> 
    );
}

export default SentimentAnalytics;
