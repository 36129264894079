import React, { useRef, useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useAppSelector } from '../app/hooks';
import { selectChatbotState } from '../features/chatbot/chatbotSlice';
import { useMediaQuery } from '@mui/material';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

interface PDFViewerProps {
  base64Pdf: string;
  defaultPage: number;
}

const PdfViewer: React.FC<PDFViewerProps> = ({ base64Pdf, defaultPage }) => {
  const chatbot = useAppSelector(selectChatbotState);  
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pagesRendered, setPagesRendered] = useState(0);
  const pageRefs = useRef<{ [key: number]: HTMLDivElement | null }>({});
  const containerRef = useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery('(max-width: 720px)');

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  function onPageRenderSuccess() {
    setPagesRendered(prev => prev + 1);
  }

  useEffect(() => {
    pageRefs.current = {};
    setPagesRendered(0);
  }, [chatbot.course]);

  useEffect(() => {
    if (numPages && numPages > 0 && pagesRendered === numPages) {
      const targetPage = pageRefs.current[defaultPage];
      if (targetPage) {
        setTimeout(() => {
          targetPage.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
      }
    }
  }, [numPages, pagesRendered, defaultPage]);

  return (
    <div ref={containerRef} style={{display: "flex", height: "calc(100% - 75px)", width: "100%", minWidth: "300px", justifyContent: "center", overflowY: "auto", overflowX: "hidden"}}>
      <Document
        file={`data:application/pdf;base64,${base64Pdf}`}
        onLoadSuccess={onDocumentLoadSuccess}
        loading="Loading PDF..."
      >
        {Array.from(new Array(numPages || 0), (_, index) => (
          <>
          <div style={{border: "1px solid #d3d3d3", marginLeft: "10px"}} key={`page_${index + 1}`} ref={el => { pageRefs.current[index + 1] = el; } }>
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              onRenderSuccess={onPageRenderSuccess}
              loading="Loading page..."
              scale={isMobile ? 0.55 : 1.2}
            />
          </div>
          <div style={{height: "10px"}} />
          </>
        ))}
      </Document>
    </div>
  );
};

export default PdfViewer;