import { createBrowserRouter, Navigate } from "react-router-dom";
import MainLayout from './components/MainLayout';
import CoursePage from './pages/CoursePage';
import LoginPage from './pages/LoginPage';
import SettingsPage from './pages/SettingsPage';
import AnalyticsPage from './pages/AnalyticsPage'

const Router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/login",
        element: <LoginPage />
      },
      {
        path: "/course/:id?",
        element: <CoursePage />
      },
      {
        path: "/settings",
        element: <SettingsPage />
      },
      {
        path: "/analytics",
        element: <AnalyticsPage />
      },
      {
        path: "*",
        element: <Navigate to="/login" />
      }
    ]
  }
]);

export default Router;