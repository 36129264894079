import './MainLayout.css';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { selectUserState } from '../features/user-info/userInfoSlice';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { useState, useMemo } from 'react';

function MainLayout() {
  const userState = useAppSelector(selectUserState);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();

  const shouldShowFullLayout = useMemo(() => {
    const fullLayoutRoutes = ["/login", "/settings", "/analytics"];
    return !fullLayoutRoutes.includes(location.pathname);
  }, [location.pathname]);

  if (location.pathname !== "/login" && (userState.user === null || userState.status === 'failed')) {
    if (location.pathname.includes("/course/")) {
      const id = location.pathname.split("/course/").pop() || "";
      localStorage.setItem("pendingCourseId", id);
    } else {
      localStorage.removeItem("pendingCourseId")
    }
    return <Navigate to="/login" />;
  }

  return (
    shouldShowFullLayout ? 
      <div className="outer-layout-container">
        <Navbar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        <div className="inner-layout-container">
          <Sidebar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
          <Outlet />
        </div>
      </div>
     : 
      <Outlet />
  );
}

export default MainLayout;